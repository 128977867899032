import { returnDateFormat } from "../../utils/date/date-utils";

export function mapperToDeclarationRequest(formData) {
  let messageToSend =  {
      "Ação da solicitação": formData.name,
      "Tipo de Declaração": formData.declarationType
  };
  if (formData.message) { 
    messageToSend = { ...messageToSend, ...{ "Mensagem": formData.message } }
  }
  if (formData.allTime) {
    messageToSend = { ...messageToSend, ...{ "Todo Tempo de Cooperado": "Sim" } }
  } else {
      messageToSend = {
        ...messageToSend, ...{
          "Data de Início": returnDateFormat(formData.startDate),
          "Data de Término": returnDateFormat(formData.endDate)
        }
      }
  }
  return messageToSend;
}
