<template>
  <b-container fluid>
    <b-card class="justify-content-center">
      <div class="custom-form-flex pb-4">
        <b-form id="alter-form" class="alter-form">
          <b-form-row class="ml-2 pr-2 pb-2 p2">
            <b-col class="large-card--begin text-size-mobile">
              Tipo de Declaração
            </b-col>
            <b-col class="large-card--end text-size-mobile">
              <div class="label-right">INSS</div>
            </b-col>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2" v-show="!form.allTime">
            <b-col class="large-card--begin text-size-mobile">
              Data de Início
            </b-col>
            <b-col class="d-flex flex-column">
              <date-picker
                datePickerId="startDate"
                :key="form.startDate"
                :dataValue="form.startDate"
                :state="validateFieldStartDate('startDate')"
                @datePickerChangedValue="onChangedStartDate"
                :required="true"
              />
            </b-col>
            <div
              class="invalid text-invalid-right"
              v-show="validateFieldStartDate('startDate') == false"
            >
              {{ startDateErrorMsg }}
            </div>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2" v-show="!form.allTime">
            <b-col class="large-card--begin text-size-mobile">
              Data de Término
            </b-col>
            <b-col class="d-flex flex-column">
              <date-picker
                datePickerId="endDate"
                :key="form.endDate"
                :dataValue="form.endDate"
                :state="validateFieldEndDate('endDate', 'startDate')"
                @datePickerChangedValue="onChangedEndDate"
                :required="true"
              />
            </b-col>
            <div
              class="invalid text-invalid-right"
              v-show="validateFieldEndDate('endDate', 'startDate') == false"
            >
              {{ endDateErrorMsg }}
            </div>
          </b-form-row>
          <b-form-row class="ml-2 pr-2 pb-2 p2 text-size-mobile">
            <b-col class="large-card--begin">
              Todo Tempo de Cooperado
            </b-col>
            <b-col>
              <b-form-checkbox
                class="ml-auto align-right-all-time"
                switch
                v-model="form.allTime"
                :state="validateCheckBox('allTime')"
                @change="configuredDate()"
              >
                {{ form.allTime ? "Sim" : "Não" }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
      <success-modal
        ref="success-modal"
        description="Sua solicitação foi enviada."
        buttonText="Ok, entendi!"
        subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
        :protocol="this.protocol"
      />
    </b-card>
    <b-button
      :disabled="loading || this.$store.getters.isAdmin"
      type="button"
      class="m-3"
      variant="primary"
      @click="onSubmit"
    >
      Solicitar declaração
      <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
    </b-button>
  </b-container>
</template>

<script>
import { DeclarationType } from "../../../../static-data/DeclarationType.js";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { addDeclaration } from "../../../../services/declaration/declaration-service";

import {
  validateField,
  requiredFieldsFilled,
  validateCheckBox,
  validateFieldStartDate,
  validateFieldEndDate,
} from "../../../../utils/validate/validate.js";
import DatePicker from "../../../../components/date-picker/DatePicker.vue";

const DefaultForm = {
  name: null,
  declarationType: "INSS",
  startDate: null,
  endDate: null,
  allTime: false,
  endDateErrorMsg: "",
  startDateErrorMsg: "",
};
export default {
  name: "declaration-informations",
  components: {
    SuccessModal,
    DatePicker,
  },
  data() {
    return {
      endDateKey: null,
      startDateKey: null,
      protocol: "",
      formSubmited: false,
      loading: false,
      form: { ...DefaultForm },
      validations: {},
      endDateErrorMsg: "",
      startDateErrorMsg: "",
      declarationTypeOptions: DeclarationType,
      validateField,
      requiredFieldsFilled,
      validateCheckBox,
      validateFieldStartDate,
      validateFieldEndDate,
    };
  },
  async mounted() {
    this.startDateKey = this.form.startDate;
    this.endDateKey = this.form.endDate;
  },
  methods: {
    async onSubmit() {
      this.formSubmited = true;
      this.loading = true;
      if (this.requiredFieldsFilled()) {
        this.form.name = "Solicitação de Declaração de INSS";
        this.protocol = await addDeclaration(
          this.form,
          this.typeSolicitacaoInternal
        );
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        (this.form = { ...DefaultForm }), (this.formSubmited = false);
      }
      this.loading = false;
    },
    configuredDate() {
      if (this.form.allTime) {
        this.form.startDate = new Date().toISOString().split("T")[0];
        this.form.endDate = new Date().toISOString().split("T")[0];
      } else {
        this.form.startDate = null;
        this.form.endDate = null;
      }
    },
    onChangedStartDate(value) {
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
div.card {
  border-color: transparent;
  border-radius: 1rem;
}

.label-right {
  width: 100%;
  text-align: right;
  padding-right: 1em;
}

@media screen and (max-width: 800px) {
  .text-size-mobile {
    font-size: 1.1rem;
  }
  .alter-form div.form-row {
    padding: 0.5rem;
  }
}
.align-right-all-time {
  margin-right: 1.2rem;
}
.text-invalid-right {
  width: 100%;
  text-align: right;
}
</style>
