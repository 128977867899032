import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";
import AlterType from "../../static-data/TypeRequestedFlow";
import requestBuilder from "../request-builder/request-builder";
import { mapperToDeclarationRequest } from "./declaration-mapper";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function addDeclaration(formData, typeRequest) {
  const type =
    typeRequest === null || typeRequest === undefined
      ? ServiceRequestType.DOCTOR_DECLARATION_SEND
      : typeRequest;
  
    const resp = await axios.post(
        `${METALICA_URL}/v2/integration-flow`,
        requestBuilder(
            mapperToDeclarationRequest(formData, FormRequestAction.update),
          AlterType.solicitation,
          [],
          type
        )
      );
      return resp.data;
  }